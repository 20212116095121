import {parseISO} from "date-fns";
import {format, toDate, utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";

const timezone = "America/Los_Angeles";
const mtv = "America/Los_Angeles";


export const viewDate = (dt, fmt) => {
    return format(utcToZonedTime(dt, timezone), fmt, {timeZone: timezone});
}

export const utcDate = (dt) => {
    return zonedTimeToUtc(dt, timezone, {timeZone: timezone});
}

export const zonedDate = (dt) => {
    return utcToZonedTime(dt, timezone, {timeZone: timezone});
}

export const mtvDate = (dt) => {
    return utcToZonedTime(dt, mtv, {timeZone: timezone});
}

export const zonedStartOfDay = (dt) => {
    return toDate(format(dt, "yyyy-MM-dd", {timeZone: mtv}) + "T00:00:00 " + mtv, {timeZone: mtv});
}

export const adjustTime = (date, time, land = false) => {
    let result = parseISO(time.toISOString());
    result.setDate(parseISO(date.toISOString()).getDate());
    result.setMonth(parseISO(date.toISOString()).getMonth());
    result.setFullYear(parseISO(date.toISOString()).getFullYear())
    let offset = -1;
    let rollover = result.getDate() > date.getDate();
    if (land) {
        offset = 1;
        rollover = date > result;
    }
    if (rollover) {
        result.setDate(parseISO(date.toISOString()).getDate() + offset);
    }
    return result.toISOString();
}
