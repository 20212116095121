import React from 'react';
import {isEqual, isWithinInterval, startOfDay} from 'date-fns';
import DataUserContext from "./dataUserContext";

export const providesSessionData = Component => {
    class ProvidesSessionData extends React.Component {
        calendarUpdate = (dateInfo) => {
            let changed = false;
            let today = startOfDay(new Date());
            let sessionData = {...this.state.sessionData};
            // Update current date - today if in range, else start of the current range
            let view = dateInfo.view;
            let viewInterval = {start: view.currentStart, end: view.currentEnd};
            if (!isEqual(sessionData.currentDate, today) &&
                isWithinInterval(new Date(), viewInterval)) {
                sessionData.currentDate = new Date();
                changed = true;
            } else {
                // Only update if outside the current interval to allow smooth month-week transitions
                sessionData.currentDate = view.currentStart;
                changed = true;
            }
            // Save the current calendar view
            if (sessionData.calendarView !== view.type) {
                sessionData.calendarView = view.type;
                changed = true;
            }
            // Load all flights from the beginning of the month
            let date = dateInfo.start;
            let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            // Load all flights to the end of the month
            date = dateInfo.end;
            let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            let loadInterval = {start: sessionData.startDate, end: sessionData.endDate};
            if (!isWithinInterval(startDate, loadInterval) || !isWithinInterval(endDate, loadInterval)) {
                sessionData.startDate = startDate;
                sessionData.endDate = endDate;
                changed = true;
            }
            if (changed) {
                this.setState({sessionData: sessionData})
            }
        }
        setDisplayMode = (value) => {
            let sessionData = {...this.state.sessionData};
            if (sessionData.displayMode !== value) {
                sessionData.displayMode = value;
                this.setState({sessionData: sessionData})
            }
        }
        constructor(props) {
            super(props);
            const date = startOfDay(new Date());
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.state = {
                sessionData: {
                    calendarView: "dayGridWeek",
                    currentDate: date,
                    startDate: firstDay,
                    endDate: lastDay,
                    calendarUpdate: this.calendarUpdate,
                    setDisplayMode: this.setDisplayMode,
                    displayMode: false,
                }
            }
        }

        render() {
            return (
                <DataUserContext.Provider value={this.state.sessionData}>
                    <Component {...this.props}/>
                </DataUserContext.Provider>
            );
        }
    }

    return ProvidesSessionData;
}

const withSessionData = Component => {
    class WithSessionData extends React.Component {
        render() {
            return (
                <DataUserContext.Consumer>
                    {sessionData =>
                        <Component sessionData={ sessionData } {...this.props} />
                    }
                </DataUserContext.Consumer>
            );
        }
    }

    return WithSessionData;
};

export default withSessionData;